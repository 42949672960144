import {makeStyles, Theme} from '@material-ui/core/styles';
import BSCTextField, {BSCTextFieldProps} from '../bscTextField/bscTextField';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import {forwardRef, KeyboardEvent, useCallback} from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    searchInput: {
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            //   width: 'auto',
            width: 'calc(100% - 20px)',
            padding: '5px 10px 8px 10px',
        },
        '& input': {
            padding: theme.spacing(1.5, 1.25),
        },
    },
    searchInputStyleProp: {
        background: theme.palette.common.white,
        borderRadius: 4,
        [theme.breakpoints.up('md')]: {
            width: '80%',
        },
        [theme.breakpoints.down('md')]: {
            //   width: 'auto',
            width: '100%',
        },
    },
    endAdornmentInput: {
        //position: 'absolute',
        right: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            right: theme.spacing(0),
        },
    },
    endAdormentClick: {
        cursor: 'pointer',
    },
}));

interface BSCSearchFieldProps extends BSCTextFieldProps {
    onEnterDown?: (keyCode: string) => void;
    loading: boolean;
    onClear?: () => void;
}
const BSCSearchField = forwardRef<HTMLDivElement, BSCSearchFieldProps>(
    ({inputKey = null, onEnterDown, loading, formStateValidations = null, onClear, value, ...otherProps}: BSCSearchFieldProps, ref) => {
        const classes = useStyles();

        const handleKeyDown = useCallback(
            (e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter' || e.code === 'Enter') {
                    onEnterDown?.('Enter');
                }
            },
            [onEnterDown]
        );

        return (
            <BSCTextField
                {...otherProps}
                ref={ref}
                onKeyDown={handleKeyDown}
                value={value}
                className={classes.searchInput}
                InputProps={{
                    className: classes.searchInputStyleProp,
                    startAdornment: (
                        <>
                            {loading && <CircularProgress className={classes.endAdornmentInput} size={20} color="primary" />}
                            {!loading && (
                                <SearchIcon
                                    className={classes.endAdornmentInput}
                                    onClick={() => {
                                        onEnterDown?.('Enter');
                                    }}
                                />
                            )}
                        </>
                    ),
                    endAdornment: (
                        <>
                            {loading && <CircularProgress className={classes.endAdornmentInput} size={20} color="primary" />}
                            {!loading && (
                                <CloseIcon onClick={onClear} className={(classes.endAdornmentInput, classes.endAdormentClick)}></CloseIcon>
                            )}
                        </>
                    ),
                }}
                inputKey={inputKey || ''}
                formStateValidations={formStateValidations}
            />
        );
    }
);

export default BSCSearchField;
