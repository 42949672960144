/* eslint-disable @typescript-eslint/no-unused-vars */
import {memo, useCallback, useRef, useState} from 'react';
import {useAlertContext} from '../../../../../AlertContext';
import {BSCWarningAlert} from '../../../../../features/common/bscAlert/bscAlert';
//eslint-disable-next-line @typescript-eslint/no-unused-vars
import BSCAddress from '../../../../../features/common/bscAddress/bscAddress';
import FormValidationsClient from '../../../../../client/s3/formValidations/formValidationsClient';
//eslint-disable-next-line @typescript-eslint/no-unused-vars
import FormValidationsNormalizer from '../../../../../client/s3/formValidations/formValidationsNormalizer';
import {useQuery} from '../../../../../packages/core/src/hooks/useQuery';
import {Address, UserApi} from '../../../../../client/openapitools/marketplace';
import {Grid} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useAuthFunctions, useAuthState} from '../../../../../AuthContext';
import {useMsal} from '@azure/msal-react';
import useReduxToast from '../../../../../features/hooks/redux/toast/useReduxToast';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../../authConfig';

const useStyles = makeStyles((theme: Theme) => ({
    paddingLeft: {
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
        },
    },
    paddingRight: {
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            paddingRight: 0,
        },
    },
}));

interface CartCheckoutHeaderProps {
    isAuth: boolean;
    userShipping: Address;
    userBilling: Address;
    isLoading: boolean;
    onAddressUpdate: Function;
}

function CartCheckoutHeader({isAuth, userShipping, userBilling, onAddressUpdate}: CartCheckoutHeaderProps) {
    const [shippingIsBilling, setShippingIsBilling] = useState(false);
    const {messages} = useAlertContext();
    const classes = useStyles();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    if (!apiAuthConfig) {
        return <BSCWarningAlert message={messages['mustSignInForCheckout']} filled></BSCWarningAlert>;
    }

    /* eslint-disable  @typescript-eslint/no-unused-vars */
    const {
        loading: formValidationsLoading,
        error: formValidationsErrors,
        data: formValidations,
    } = useQuery(() => FormValidationsClient.getFormValidations(), []);

    const userApi = new UserApi(apiAuthConfig);

    const [loading, setLoading] = useState(false);
    const addUserAddress = address => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const userApi = new UserApi(apiConfig);
                            setLoading(true);
                            const saveResponse = await userApi.saveAddressByType(address).catch(() => userApi.updateAddress(address));
                            if (saveResponse.status === 200 || saveResponse.status === 201) {
                                onAddressUpdate();
                                return saveResponse;
                            }
                            setLoading(false);
                        } catch (error) {
                            console.error('Error updating address', error);
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred.',
                                contextKey: 'Address Update',
                            });
                            setLoading(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const onSave = useCallback(
        async address => {
            setShippingIsBilling(false);
            //Make sure res is returned.  Otherwise any promisses down the chain break
            addUserAddress({...address, country: 'US'});
        },
        [addUserAddress, userShipping, userBilling]
    );

    const billingAddressRef = useRef({setEditFormAddress: address => {}});

    const [duplicateAddress, setDuplicateAddress] = useState({});
    const handleShippingIsBilling = (status: boolean, addressType: string) => {
        setShippingIsBilling(status);
        if (addressType === 'billing') {
            if (status) {
                billingAddressRef.current.setEditFormAddress(userShipping);
                setDuplicateAddress(userShipping);
            } else {
                billingAddressRef.current.setEditFormAddress(userBilling);
                setDuplicateAddress(userBilling);
            }
        } else {
            if (status) {
                billingAddressRef.current.setEditFormAddress(userBilling);
                setDuplicateAddress(userBilling);
            } else {
                billingAddressRef.current.setEditFormAddress(userShipping);
                setDuplicateAddress(userShipping);
            }
        }
    };

    return (
        <Grid container direction="row">
            <Grid item md={6} xs={12} className={classes.paddingRight}>
                <BSCAddress
                    ref={billingAddressRef}
                    address={userShipping}
                    addressTypeDisplay="Shipping Address"
                    addressType="shipping"
                    formValidation={FormValidationsNormalizer.getShippingAddressValidationsSchema(formValidations)}
                    onSaveAddress={onSave}
                    isBillingSameAsShippingChecked={shippingIsBilling}
                    onBillingSameAsShippingChecked={e => handleShippingIsBilling(e, 'shipping')}
                    isCartAddress={true}
                    duplicateAddress={duplicateAddress}
                ></BSCAddress>
            </Grid>
            <Grid item md={6} xs={12} className={classes.paddingLeft}>
                <BSCAddress
                    ref={billingAddressRef}
                    address={userBilling}
                    addressTypeDisplay="Billing Address"
                    addressType="billing"
                    formValidation={FormValidationsNormalizer.getShippingAddressValidationsSchema(formValidations)}
                    onSaveAddress={onSave}
                    isBillingSameAsShippingChecked={shippingIsBilling}
                    onBillingSameAsShippingChecked={e => handleShippingIsBilling(e, 'billing')}
                    isCartAddress={true}
                    duplicateAddress={duplicateAddress}
                ></BSCAddress>
            </Grid>
        </Grid>
    );
}

export default memo(CartCheckoutHeader);
