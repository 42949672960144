/* eslint-disable @typescript-eslint/no-unused-vars */
import {makeStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import SwiperCore from 'swiper';
import {Navigation} from 'swiper/modules';
import {SwiperSlide} from 'swiper/react';
import 'swiper/scss';
import Normalizer from '../../../../../client/common/normalizer/normalizer';
import BSCImage from '../../../../../features/common/bscImage/bscImage';
import BSCSwiper from '../../../../../features/common/bscSwiper/bscSwiper';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import styles from './Styles';

const baseStyles = makeStyles(() => ({
    h1Zero: {
        display: 'none',
        width: 0,
        height: 0,
        margin: 0,
        padding: 0,
        color: 'transparent',
    },
}));

SwiperCore.use([Navigation]);

export default function SportSlider(sliderData) {
    const classes = styles();
    const baseClasses = baseStyles();
    const {isBelowTablet, isTablet, isMobile} = useScreenSnap();
    const [desktopSlideFactor, setDesktopSlideFactor] = useState(0);

    useEffect(() => {
        const deviceScreenWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
        let deviceFactor = deviceScreenWidth / 265;
        if (Number.isInteger(deviceFactor)) {
            deviceFactor += 0.25;
        }
        setDesktopSlideFactor(deviceFactor);
    }, []);

    return (
        <>
            {sliderData &&
                sliderData.sliders &&
                sliderData.sliders.length > 0 &&
                sliderData.sliders.map((slider, index) => (
                    <Grid container alignItems="center" key={index}>
                        <Grid item xs={12} className={classes.filterWrap}>
                            <Grid container alignItems="center" justifyContent={'flex-start'}>
                                <BSCTypography variant="body1" style={{fontSize: 22, fontWeight: 700}} label={`${slider.slider_name}:`} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{marginBottom: 8}}>
                            <BSCSwiper
                                marginTop="4%"
                                className={classes.sidePadding}
                                hideNavOnDesktop={desktopSlideFactor > slider.items.length ? true : false}
                                swiperKey={slider.slider_key}
                                spaceBetween={4}
                                slidesPerView={
                                    isTablet ? 4.6 : desktopSlideFactor > slider.items.length ? slider.items.length : desktopSlideFactor
                                }
                            >
                                {slider.items.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <Link to={item.slug}>
                                            {/* <BSCImage className={classes.sportImage} src={Normalizer.getS3ImagePath(sport.img)} /> */}
                                            <BSCImage
                                                className={classes.sportImage}
                                                alt={item.img_alt}
                                                style={{padding: '0px'}}
                                                title={item.title}
                                                src={Normalizer.getS3ImagePath(item.image)}
                                            />
                                        </Link>
                                    </SwiperSlide>
                                ))}
                            </BSCSwiper>
                        </Grid>
                    </Grid>
                ))}
        </>
    );
}
