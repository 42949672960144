import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useQuery} from '../packages/core/src/hooks/useQuery';
import FooterClient from '../client/s3/footer/footerClient';
import BSCContainer from '../features/common/bscContainer/bscContainer';
import BSCTypography from '../features/common/bscTypography/bscTypography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import LinkGroup from './linkGroup';
import SocialLinkIconGroup from './socialLinkIconGroup';
import classNames from 'classnames';
import useScreenSnap from '../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
        background: '#000000',
    },
    shop: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    sell: {
        width: '20%',
        [theme.breakpoints.down('sm')]: {
            width: '50%',
        },
    },
    linkWrapper: {
        padding: '5px',
    },
    link: {
        color: '#F5F8FA',
    },
    termsTitle: {
        color: theme.palette.common.white,
        fontSize: 10,
        fontStyle: 'unset',
        fontWeight: 'bold',
    },
    group: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

const BSCFooter = ({isMarketPlace}) => {
    const classes = useStyles();
    const {data} = useQuery(
        () => (isMarketPlace ? FooterClient.getMarketPlaceFooter() : FooterClient.getSellersLockerFooter()),
        [isMarketPlace]
    );
    const isSellers = useRouteMatch('/sellers');

    const {isDesktop} = useScreenSnap();

    return (
        <div className={classes.root}>
            <BSCContainer variant="page">
                <Grid container spacing={4}>
                    {(data?.shop || data?.sell || data?.about || data?.help || data?.followus) && (
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                {data?.shop && (
                                    <Grid item className={classes.shop}>
                                        <LinkGroup title={data?.shop.title} links={data?.shop.links} col={6} />
                                    </Grid>
                                )}
                                {data?.sell && (
                                    <Grid item className={classes.sell}>
                                        <LinkGroup title={data?.sell.title} links={data?.sell.links} col={12} />
                                    </Grid>
                                )}
                                {data?.about && (
                                    <Grid item className={classes.sell}>
                                        <LinkGroup title={data?.about.title} links={data?.about.links} col={12} />
                                    </Grid>
                                )}
                                <Grid item className={classNames(classes.sell, classes.group)}>
                                    <Grid container spacing={2}>
                                        {data?.help && (
                                            <Grid item xs={6} md={12}>
                                                <LinkGroup title={data?.help.title} links={data?.help.links} col={12} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                {data?.followus && (
                                    <Grid item className={classes.sell}>
                                        <SocialLinkIconGroup title={data?.followus.title} links={data?.followus.links} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" alignItems="center" direction={isDesktop ? 'row' : 'column-reverse'}>
                            {data?.terms && (
                                <Grid item>
                                    <BSCTypography label="©2024 BuySportsCards.com Inc." className={classes.termsTitle} />
                                </Grid>
                            )}
                            {data?.terms && (
                                <Grid item style={{minWidth: 300}}>
                                    <Grid container spacing={2} direction="row">
                                        {data?.terms.links &&
                                            data?.terms.links.map(link => {
                                                return (
                                                    <Grid item xs={!isSellers ? (isDesktop ? 6 : 6) : isDesktop ? 4 : 4} key={link.url}>
                                                        <MenuItem
                                                            className={classes.linkWrapper}
                                                            component={Link}
                                                            to={link.url}
                                                            onClick={() => {
                                                                window.scrollTo(0, 0);
                                                            }}
                                                            style={{justifyContent: 'center'}}
                                                        >
                                                            <BSCTypography
                                                                label={link.title}
                                                                variant="subtitle1"
                                                                className={classes.termsTitle}
                                                            />
                                                        </MenuItem>
                                                    </Grid>
                                                );
                                            })}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </BSCContainer>
        </div>
    );
};

export default BSCFooter;
