import {memo} from 'react';
import DesktopNav from './desktopNav/desktopNav';
import NavigationClient from '../../../client/s3/mpNavigation/navigationClient';
import MobileNav from './mobileNav/mobileNav';
import useScreenSnap from '../../../packages/core/src/hooks/useScreenSnap';
import {useQuery} from '../../../packages/core/src/hooks/useQuery';

function MarketplaceHeader() {
    const {isTablet, isMobile} = useScreenSnap();
    const {loading: menusLoading, data: menus} = useQuery(() => NavigationClient.getMenus(), []);
    const {loading: userMenuLoading, data: userMenu} = useQuery(() => NavigationClient.getUserMenus(), []);
    return !isTablet && !isMobile ? (
        <DesktopNav menus={menus} menusLoading={menusLoading} userMenuLoading={userMenuLoading} userMenu={userMenu} />
    ) : (
        <MobileNav menus={menus} menusLoading={menusLoading} userMenuLoading={userMenuLoading} userMenu={userMenu} />
    );
}

export default memo(MarketplaceHeader);
