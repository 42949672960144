/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import * as constants from '../../../../shared/constants';
import {useDispatch} from 'react-redux';
import {Switch, Route, useHistory, useParams, Redirect} from 'react-router';
import {makeStyles} from '@material-ui/core/styles';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import SellYourCardSidebar from './components/sellYourCardSidebar';
import SellYourCardTabs from './components/sellYourCardTabs';
import BSCBack from '../../../../features/common/bscBack/bscBack';
import Grid from '@material-ui/core/Grid';
import SellYourCardAdd from './components/sellYourCardAdd';
import SellYourCardUpdate from './components/sellYourCardUpdate';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import {AccountApi, Product} from '../../../../client/openapitools/seller';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {useMsal} from '@azure/msal-react';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {Configuration} from '../../../../client/openapitools/common';
import {AUTH_REQUESTS} from '../../../../authConfig';

const useStyles = makeStyles(() => ({
    root: {
        padding: '0 0 8px 0',
    },
}));

function SellYourCard() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {addToast} = useReduxToast();
    const {isBelowTablet, isMobile} = useScreenSnap();
    const {cardId} = useParams<{cardId: string}>();
    const [newSettings, setNewSettings] = useState(null);
    const {apiAuthConfig} = useAuthState();
    const {instance} = useMsal();
    const authFunc = useAuthFunctions();

    // const {
    //     data: sellerCard,
    //     loading: sellerCardLoading,
    //     instance: sellerCardInstance,
    // } = useQuery(() => accountApi.getCardListings(cardId), [cardId]);

    // const {data: sellerSettings, loading: sellerSettingsLoading} = useQuery(() => accountApi.getProductConfig(cardId), [cardId]);

    const [isVintage, setVintage] = useState(false);
    const [sportId, setSportId] = useState(null);

    const [sellerCard, setSellerCard] = useState(null);
    const [sellerCardLoading, setSellerCardLoading] = useState(true);
    const [sellerCardInstance, setSellerCardInstance] = useState(null);
    const [sellerSettings, setSellerSettings] = useState(null);
    const [sellerSettingsLoading, setSellerSettingsLoading] = useState(true);
    useEffect(() => {
        const account = instance.getActiveAccount();
        if (cardId && account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            setSellerCardLoading(true);
                            const accountApi = new AccountApi(apiConfig);
                            const cardListingsResponse = await accountApi.getCardListings(cardId);
                            if (cardListingsResponse.status === 200) {
                                setSellerCard(cardListingsResponse);
                                dispatch({
                                    type: constants.CARD_IMAGE_DEFAULT,
                                    payload: {
                                        imageFront: cardListingsResponse.data.stockImgFront,
                                        imageBack: cardListingsResponse.data.stockImgBack,
                                    },
                                });
                                if (cardListingsResponse.data.year) {
                                    const tempYear = Number(cardListingsResponse.data.year);
                                    if (tempYear <= 1980) {
                                        setVintage(true);
                                    } else {
                                        setVintage(false);
                                    }
                                }
                                setSportId(cardListingsResponse.data.sport);
                            }
                            setSellerCardLoading(false);
                            setSellerSettingsLoading(true);
                            const productConfigResponse = await accountApi.getProductConfig(cardId);
                            if (productConfigResponse.status === 200) {
                                setSellerSettingsLoading(false);
                                setSellerSettings(productConfigResponse);
                                setSellerCardInstance(productConfigResponse);
                            }
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error fetching Card listings',
                                contextKey: 'Sell Your Card',
                            });
                            setSellerCardLoading(false);
                            setSellerSettingsLoading(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [cardId, instance, apiAuthConfig]);

    const [addCardLoading, setAddCardLoading] = useState(false);
    const [listings, setListings] = useState<Product[]>();
    const [totalAvailableQuantity, setTotalAvailableQuantity] = useState(0);

    useEffect(() => {
        setListings(sellerCard?.data.listings);
        if (sellerCard && sellerCard.data) {
            let total = 0;
            sellerCard.data.listings?.forEach(listing => (total += listing.availableQuantity));
            setTotalAvailableQuantity(total);
        }
    }, [sellerCard]);

    const handleAddCard = data => {
        const account = instance.getActiveAccount();
        if (cardId && account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            setAddCardLoading(true);
                            let additionalQuantity = 0;
                            additionalQuantity = totalAvailableQuantity + parseInt(data.availableQuantity, 10);
                            setTotalAvailableQuantity(additionalQuantity);
                            data.productId = newSettings?.productId ?? sellerSettings.data.productId;
                            data['sportId'] = sportId;
                            // API removed from the data object
                            if (data.quantity) {
                                delete data.quantity;
                            }
                            const accountApi = new AccountApi(apiConfig);
                            const sellerCardInformation = {
                                action: 'add',
                                listing: {...data},
                            };
                            const sellerInfoResponse = await accountApi.updateSellerCardInformation(sellerCardInformation, cardId);
                            if (sellerInfoResponse.status === 200) {
                                setAddCardLoading(false);
                                setListings(sellerInfoResponse.data.listings);
                                addToast({
                                    severity: 'success',
                                    message: 'Listing Added',
                                });
                                dispatch({
                                    type: constants.SELLERS_LOCKER_CARDS_ACTIVE_TAB,
                                    payload: {sellersLockerCardsActiveTab: 1},
                                });
                                accountApi.getProductConfig(cardId).then(productConfigResponse => {
                                    setNewSettings(productConfigResponse.data);
                                });
                                setTimeout(() => {
                                    history.goBack();
                                }, 1000);
                            }
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error updating Seller Card Information',
                                contextKey: 'Sell Your Card',
                            });
                            setAddCardLoading(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    return (
        <>
            {
                <>
                    <BSCContainer className={classes.root} variant="page">
                        {!isMobile && !isBelowTablet && <BSCBack label="Back" />}
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <SellYourCardSidebar sellerResults={sellerCard?.data} />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <SellYourCardTabs listingsCount={totalAvailableQuantity} />
                                <Switch>
                                    <Redirect from="/sellers/sell-your-card/" to={`/sellers/sell-your-card/add/${cardId}`} exact />
                                    <Route path={'/sellers/sell-your-card/add/:cardId'}>
                                        <SellYourCardAdd
                                            updateProduct={handleAddCard}
                                            sellerSettings={newSettings ?? sellerSettings?.data}
                                            sellerCardData={sellerCard?.data}
                                            loading={sellerSettingsLoading || addCardLoading}
                                            isVintage={isVintage}
                                        />
                                    </Route>
                                    <Route exact path={'/sellers/sell-your-card/edit/:cardId'}>
                                        <SellYourCardUpdate
                                            sellerCardInstance={sellerCardInstance}
                                            cardListing={listings}
                                            sellerSettings={sellerSettings?.data}
                                            sellerCardData={sellerCard?.data}
                                            loading={sellerCardLoading || sellerSettingsLoading}
                                            isVintage={isVintage}
                                        />
                                    </Route>
                                </Switch>
                            </Grid>
                        </Grid>
                    </BSCContainer>
                </>
            }
        </>
    );
}

export default SellYourCard;
