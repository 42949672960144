/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import UserInfo from './components/userInfo';
import useReduxUser from '../../../../../../features/hooks/redux/user/useReduxUser';
import Payment from './components/payment';
import Notification from './components/notification';
import CartCheckoutHeader from '../../../cart/header/cartCheckoutHeader';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {useAuthFunctions, useAuthState} from '../../../../../../AuthContext';
import useReduxToast from '../../../../../../features/hooks/redux/toast/useReduxToast';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../../../authConfig';
import {UserApi} from '../../../../../../client/openapitools/marketplace';
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: '678px',
        margin: '0 auto',
    },
    wrapper: {
        padding: theme.spacing(2),
        background: 'white',
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: '4px',
        marginBottom: theme.spacing(2),
    },
}));

function Account() {
    const classes = useStyles();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const {setUser} = useReduxUser();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    // const isAuthenticated = useIsAuthenticated();
    const {userState, userShipping, userBilling, userLoading} = useReduxUser();

    const [refreshFlag, setRefreshFlag] = React.useState(false);
    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const userApi = new UserApi(apiConfig);
                            const response = await userApi.getUserProfile();
                            setUser(response.data);
                        } catch (error) {
                            console.error('Error fetching user', error);
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred.',
                                contextKey: 'Authentication',
                            });
                        }
                    }
                })
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance, refreshFlag]);

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <UserInfo user={userState} />
            </div>
            <CartCheckoutHeader
                isLoading={userLoading}
                userShipping={userShipping}
                userBilling={userBilling}
                isAuth={true}
                onAddressUpdate={() => setRefreshFlag(!refreshFlag)}
            />
            <div className={classes.wrapper}>
                <Payment userBilling={userBilling} showRadioButton={false} />
            </div>
            <div className={classes.wrapper}>
                <Notification />
            </div>
        </div>
    );
}

export default Account;
