/* eslint-disable */
import * as constants from '../../../../shared/constants';
const queryString = require('query-string');
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {RootState} from '../redux/reducers';
import SearchMenuHeader from './SearchMenuHeader';
import SearchSidebar from './sidebar/SearchSidebar';
import SearchResults from './SearchResults';
import {useCallback} from 'react';
import SearchShopResultsClient from '../../../../client/search/shop/results/searchShopResultsClient';
import {makeStyles, Theme} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {useQuery} from '../../../../packages/core/src/hooks/useQuery';
import BSCPagination from '../../../../features/common/bscPagination/bscPagination';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import Box from '@material-ui/core/Box';
import useSearch from '../../../../packages/core/src/hooks/useSearch';
import BSCHidingHeaderBar from '../../../../features/common/bscHidingHeaderBar/bscHidingHeaderBar';
import {clearSearch} from '../../../../redux/slices/marketplaceSearch.slice';
import {QueryString} from '../../../../packages/core/src/util/queryString';

const useStyles = makeStyles((theme: Theme) => ({
    searchPageContainer: {
        //padding: theme.spacing(1, 3),
        //overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        flexDirection: 'row',
        maxWidth: '99%',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '99%',
        },
        // margin: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
    },
    drawerPaper: {
        width: '90%',
    },
    searchBar: {
        padding: theme.spacing(1, 2),
        position: 'sticky',
        top: 140,
        flex: 3,
        maxWidth: 300,
        alignSelf: 'flex-start',
        height: 'auto',
        [theme.breakpoints.down('lg')]: {
            maxWidth: 300,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            display: 'none',
        },
        verticalAlign: 'top',
    },
    searchResults: {
        [theme.breakpoints.down('sm')]: {
            // paddingLeft: theme.spacing(2),
            // paddingRight: theme.spacing(2),
            marginLeft: 0,
            display: 'block',
        },
    },
    searchWrap: {
        //display: 'inline-block',
    },
    searchPageCol: {
        paddingTop: theme.spacing(1),
        flexDirection: 'column',
        flex: 4,
    },
}));

function SearchPage() {
    const classes = useStyles();
    const {search} = useLocation();

    const sellerId = new URLSearchParams(search).get('sellerId');
    const sellerName = new URLSearchParams(search).get('sellerName');
    useEffect(() => {
        if (sellerId && sellerName) {
            dispatch({
                type: constants.SELLER_STOREFRONT_SELECTED,
                payload: {sellerId: sellerId, sellerName: sellerName},
            });
        }
    }, [sellerId, sellerName]);
    const {isMobile, isTablet, isBelowTablet, isDesktop} = useScreenSnap();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {
        cleanedSearchQuery,
        handleCheckboxChange,
        handleClearAll,
        handlePaging,
        handleParamDelete,
        handleSortChange,
        handleRadioChange,
        handleStockToggleChange,
        handleConditionsChange,
        handlePriceRangeChange,
    } = useSearch();
    const {sort, p, inStock, condition} = cleanedSearchQuery;
    const [drawerOpen, setDrawerOpen] = useState(false);
    const dispatch = useDispatch();
    const belowTablet = isTablet || isMobile;
    const [selectedSellerId, setSelectedSellerId] = useState(null);
    const [sessionInStock, setSessionInStock] = useState(false);
    const {
        loading,
        error: errors,
        data: marketPlaceResults,
    } = useQuery(
        () =>
            SearchShopResultsClient.marketPlaceSearch({
                terms: cleanedSearchQuery,
                sellerKey: selectedSellerId,
            }),
        [cleanedSearchQuery, selectedSellerId]
    );
    const aggregations = marketPlaceResults?.data?.aggregations;
    const searchResults = marketPlaceResults?.data?.results;
    let appliedFilters = marketPlaceResults?.data?.appliedFilters;
    const totalResults = marketPlaceResults?.data?.totalResults;
    const aggregatedValues = aggregations ?? null;
    const aggregatedKeys = aggregations && Object.keys(aggregations);

    const handleCloseDrawer = useCallback(() => {
        setDrawerOpen(false);
    }, []);

    const [mobileFilterCount, setMobileFilterCount] = useState(0);
    useEffect(() => {
        if (cleanedSearchQuery.q) appliedFilters = null;
    }, [cleanedSearchQuery.q]);

    useEffect(() => {
        let filterCount = 0;
        if (cleanedSearchQuery) {
            const {
                sort,
                inStock,
                rawCondition,
                gradingCompany,
                letterGrade,
                gradeRangeStart,
                gradeRangeEnd,
                priceRangeStart,
                priceRangeEnd,
            } = cleanedSearchQuery;
            if (rawCondition) {
                filterCount += rawCondition.length;
            }
            if (gradingCompany) {
                filterCount += gradingCompany.length;
            }
            if (letterGrade) {
                filterCount += letterGrade.length;
            }
            if (gradeRangeStart) {
                filterCount++;
            }
            if (gradeRangeEnd) {
                filterCount++;
            }
            if (priceRangeStart || priceRangeEnd) {
                filterCount++;
            }
            dispatch({
                type: constants.BREADCRUMBS_CARD_CONDITION,
                payload: {breadcrumbsCardCondition: condition},
            });
            dispatch({
                type: constants.BREADCRUMBS_IN_STOCK,
                payload: {breadcrumbsInStock: inStock === 'true' ? true : false},
            });
            dispatch({
                type: constants.BREADCRUMBS_SORT_ORDER,
                payload: {breadcrumbsSortOrder: sort},
            });
        }
        if (appliedFilters && appliedFilters.length > 0 && cleanedSearchQuery.q) {
            for (let i = 0; i < appliedFilters.length; i++) {
                if (!cleanedSearchQuery[appliedFilters[i].type]) {
                    cleanedSearchQuery[appliedFilters[i].type] = [];
                }
                cleanedSearchQuery[appliedFilters[i].type].push(appliedFilters[i].slug);
            }
            cleanedSearchQuery.q = null;
            cleanedSearchQuery.inStock = sessionInStock;
            cleanedSearchQuery.condition = condition ?? 'all';
        }
        if (appliedFilters && appliedFilters.length > 0) {
            filterCount += appliedFilters.length;
        }
        setMobileFilterCount(filterCount);
    }, [cleanedSearchQuery, appliedFilters]);

    useEffect(() => {
        if (marketplaceState) {
            const tempSellerId = marketplaceState.sellerStorefront !== {} ? marketplaceState.sellerStorefront['sellerId'] : null;
            setSelectedSellerId(tempSellerId);
            setSessionInStock(marketplaceState.sessionInStock);
        }
    }, [marketplaceState]);

    const handleOpenDrawer = useCallback(() => {
        setDrawerOpen(true);
    }, []);

    const handlePageChange = (evt, pageNumber) => {
        if (isMobile && marketplaceState.scrollableId !== '') {
            dispatch({
                type: constants.SET_MARKETPLACE_SCROLLABLE_ID,
                payload: {scrollableId: ''},
            });
        }
        handlePaging(evt, pageNumber);
    };

    const onClearAll = () => {
        handleClearAll();
        dispatch(clearSearch());
        window.scrollTo(0, 0);
    };
    useEffect(() => {
        const parsed = queryString.parse(window.location.search);
        let titleSport = parsed['sport[]'];
        if (titleSport) {
            titleSport = titleSport.replace(/\b\w/g, l => l.toUpperCase());
            if (titleSport === 'Mma') {
                titleSport = 'MMA';
            }
            document.title = `BuySportsCards: Shop ${titleSport} Cards`;
        } else {
            document.title = 'BuySportsCards.com: Millions of Sports Cards for Sale';
        }
    }, []);

    return (
        <div>
            {isMobile && (
                <BSCHidingHeaderBar relativePositionThreshold={125} disapearingThreshold={140}>
                    <SearchMenuHeader
                        loading={loading}
                        filterCount={mobileFilterCount}
                        totalResults={totalResults || 0}
                        selectedSortValue={sort || 'best-sellers'}
                        onSortChange={handleSortChange}
                        onOpenDrawer={handleOpenDrawer}
                    />
                </BSCHidingHeaderBar>
            )}
            {!isMobile && (
                <SearchMenuHeader
                    loading={loading}
                    filterCount={appliedFilters?.length ?? 0}
                    totalResults={totalResults || 0}
                    selectedSortValue={sort || 'best-sellers'}
                    onSortChange={handleSortChange}
                    onOpenDrawer={handleOpenDrawer}
                />
            )}
            <div className={classes.searchPageContainer}>
                <Box className={classes.searchBar}>
                    {belowTablet ? (
                        <SwipeableDrawer
                            anchor="left"
                            open={drawerOpen}
                            onClose={handleCloseDrawer}
                            onOpen={handleOpenDrawer}
                            classes={{paper: classes.drawerPaper}}
                        >
                            <SearchSidebar
                                isMobile={belowTablet}
                                loading={loading}
                                errors={errors}
                                aggregatedKeys={aggregatedKeys}
                                aggregatedValues={aggregatedValues}
                                parsedQueryResults={cleanedSearchQuery}
                                chipList={appliedFilters}
                                onRadioChange={handleRadioChange}
                                onCheckboxChange={handleCheckboxChange}
                                onClearAll={onClearAll}
                                onStockToggleChange={handleStockToggleChange}
                                onConditionsChange={handleConditionsChange}
                                onPriceRangeChange={handlePriceRangeChange}
                                onParamDelete={handleParamDelete}
                                showFindASeller={true}
                                inStockToggle={sessionInStock?.toString()}
                                showInStock={true}
                                showCardType={true}
                                showSelectSeller={true}
                                showConditions={true}
                                showPriceRange={true}
                                showSortOrder={false}
                            />
                        </SwipeableDrawer>
                    ) : (
                        <SearchSidebar
                            isMobile={belowTablet}
                            loading={loading}
                            errors={errors}
                            aggregatedKeys={aggregatedKeys}
                            aggregatedValues={aggregatedValues}
                            parsedQueryResults={cleanedSearchQuery}
                            chipList={appliedFilters}
                            onRadioChange={handleRadioChange}
                            onCheckboxChange={handleCheckboxChange}
                            onClearAll={onClearAll}
                            onStockToggleChange={handleStockToggleChange}
                            onConditionsChange={handleConditionsChange}
                            onPriceRangeChange={handlePriceRangeChange}
                            onParamDelete={handleParamDelete}
                            showFindASeller={true}
                            inStockToggle={sessionInStock?.toString()}
                            showInStock={true}
                            showCardType={true}
                            showSelectSeller={true}
                            showConditions={true}
                            showPriceRange={true}
                            showSortOrder={false}
                        />
                    )}
                </Box>
                <div className={classes.searchPageCol}>
                    <Box className={classes.searchWrap}>
                        <Box className={classes.searchResults}>
                            <SearchResults
                                loading={loading}
                                results={searchResults}
                                chipList={appliedFilters}
                                errors={errors}
                                sortOrder={sort}
                                condition={condition}
                                currentPage={p}
                            />
                        </Box>

                        <BSCDivider />
                        <BSCPagination
                            total={totalResults}
                            defaultPage={parseInt(p) + 1 > 0 ? parseInt(p) + 1 : 1}
                            sizePerPage={48}
                            onChange={handlePageChange}
                        />
                    </Box>
                </div>
            </div>
        </div>
    );
}

export default SearchPage;
