import * as constants from '../../../../shared/constants';
import {memo, useEffect, useReducer, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../../../../redux/reducers';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import BSCSelect from '../../../../features/common/bscSelect/bscSelect';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import {makeStyles, Theme} from '@material-ui/core/styles';
import classNames from 'classnames';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import {numberWithCommas} from '../../../../packages/core/src/util/util';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: theme.palette.common.white,
        //marginBottom: theme.spacing(1),
        //   boxShadow: '1px 1px 20px rgba(0, 0, 0, 0.1)',
        position: 'sticky',
        [theme.breakpoints.up('md')]: {
            top: 80,
            zIndex: 2,
        },
    },
    container: {
        // padding: theme.spacing(0, 2),
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        width: '48%',
        [theme.breakpoints.up('md')]: {
            width: 'auto',
        },
        fontSize: '16px',
    },
    sortByWrap: {
        display: 'flex',
        alignItems: 'center',
        width: '48%',
        [theme.breakpoints.up('md')]: {
            width: 'auto',
        },
    },
    sortBy: {
        marginRight: '16px',
        padding: '8px, 16px',
        color: '#000000',
    },
    select: {
        width: '100%',
        height: '38px',
        '& > div': {
            height: '100%',
            '& > div': {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& > svg': {
                    top: 8,
                },
            },
        },
        fontSize: '14px!important' as '14px',
        [theme.breakpoints.up('md')]: {
            width: 'auto',
        },
    },
    bestSellerText: {
        fontSize: '14px!important' as '14px',
    },
    loadingWrap: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    },
}));

interface SearchMenuHeaderProps {
    loading: boolean;
    filterCount?: Number;
    totalResults?: Number;
    onSortChange: (value) => void;
    selectedSortValue: string;
    onOpenDrawer: () => void;
}

function SearchMenuHeader({
    loading,
    filterCount,
    totalResults = 0,
    onSortChange,
    selectedSortValue,
    onOpenDrawer,
    ...otherProps
}: SearchMenuHeaderProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {isDesktop, isBelowTablet} = useScreenSnap();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const [isSellerStorefrontPresent, setIsSellerStorefrontPresent] = useState(false);

    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [isSearching, setIsSearching] = useState(false);
    const updateSortState = value => {
        dispatch({
            type: constants.UPDATE_SEARCH_SORT_PARAM,
            payload: {searchSortParam: value},
        });
        setIsSearching(true);
        forceUpdate();
        onSortChange(value);
    };

    useEffect(() => {
        if (!loading) {
            setIsSearching(false);
        }
    }, [loading]);

    useEffect(() => {
        if (marketplaceState) {
            const tempSellerName = marketplaceState.sellerStorefront !== {} ? marketplaceState.sellerStorefront['sellerName'] : null;
            if (tempSellerName && tempSellerName !== null) {
                setIsSellerStorefrontPresent(true);
            } else {
                setIsSellerStorefrontPresent(false);
            }
        }
    }, [marketplaceState]);

    return (
        <div className={classes.root} {...otherProps}>
            <BSCContainer variant="page" className={classes.container}>
                <Grid container alignItems="center" justifyContent="space-between">
                    {isDesktop && (
                        <BSCTypography
                            variant="subtitle1"
                            color="textPrimary"
                            capitalize
                            label={isSellerStorefrontPresent ? '' : `${numberWithCommas(totalResults || 0)} results`}
                        />
                    )}
                    {isBelowTablet && (
                        <BSCButton className={classes.button} fullWidth color="primary" variant="contained" onClick={onOpenDrawer}>
                            <BSCTypography size14 label={`Filters ${filterCount > 0 ? '(' + filterCount.toString() + ')' : ''}`} />
                        </BSCButton>
                    )}
                    <div className={classes.sortByWrap}>
                        {isDesktop && <BSCTypography variant="subtitle1" capitalize className={classes.sortBy} label="Sort By:" />}
                        {isSearching && (
                            <div className={classes.loadingWrap}>
                                <CircularProgress color="secondary" />
                            </div>
                        )}
                        {!isSearching && (
                            <BSCSelect
                                className={classNames(classes.select, classes.bestSellerText)}
                                selectedSortValue={selectedSortValue}
                                onSortChange={updateSortState}
                                disabled={loading}
                            >
                                <MenuItem className={classes.bestSellerText} value="" disabled>
                                    Select
                                </MenuItem>
                                <MenuItem className={classes.bestSellerText} value="best-sellers">
                                    Relevance
                                </MenuItem>
                                <MenuItem className={classes.bestSellerText} value="recently-added">
                                    Recently Added
                                </MenuItem>
                                <MenuItem className={classes.bestSellerText} value="raw-price-hi-lo">
                                    Price: Hi to Low
                                </MenuItem>
                                <MenuItem className={classes.bestSellerText} value="raw-price-lo-hi">
                                    Price: Low to Hi
                                </MenuItem>
                                <MenuItem className={classes.bestSellerText} value="card-no-1-z">
                                    Card #
                                </MenuItem>
                                <MenuItem className={classes.bestSellerText} value="newest">
                                    Newest
                                </MenuItem>
                                <MenuItem className={classes.bestSellerText} value="oldest">
                                    Oldest
                                </MenuItem>
                                <MenuItem className={classes.bestSellerText} value="a-z">
                                    A-Z
                                </MenuItem>
                                <MenuItem className={classes.bestSellerText} value="z-a">
                                    Z-A
                                </MenuItem>
                            </BSCSelect>
                        )}
                    </div>
                </Grid>
            </BSCContainer>
        </div>
    );
}

export default memo(SearchMenuHeader);
