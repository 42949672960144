import React, {useEffect, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCSelect from '../../../../features/common/bscSelect/bscSelect';
import BSCHeaderBlock from '../../../../features/common/bscHeaderBlock/bscHeaderBlock';
import SellerStickyHeadTable from './sellerMessageTable';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {useMsal} from '@azure/msal-react';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {ConversationApi} from '../../../../client/openapitools/common/api';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {Configuration} from '../../../../client/openapitools/common';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        background: '#fff',
        boxShadow: '0px 0px 4px #B8C2CC',
        // borderRadius: '4px 4px 0 0',
        padding: theme.spacing(1.5, 2),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            padding: '0',
        },
    },
    menu: {
        display: 'flex',
        width: 'fit-content',
        alignItems: 'center',
        padding: theme.spacing(0.5, 2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0.5, 2),
        },
    },
    pageSizeLabel: {
        display: 'flex',
        height: '48px',
        alignItems: 'center',
    },
    expandIcon: {
        color: '#EA3E2E',
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    headerLabel: {
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.subtitle2.fontSize,
        },
    },
}));

function SellersMessageList({msgRef}) {
    const classes = useStyles();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    let conversationApi = new ConversationApi(apiAuthConfig);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const {isMobile} = useScreenSnap();
    const [perPage, setPerPage] = useState<number>(10);
    const [page, setPage] = useState(0);
    // const {loading: conversationHistoryLoading, data: conversationHistory} = useQuery(
    //     () => conversationApi.getConversationsForSeller(),
    //     []
    // );
    const [conversationHistory, setConversationHistory] = useState(null);
    const [conversationHistoryLoading, setConversationHistoryLoading] = useState(true);

    const conversationHistoryResults = conversationHistory?.data?.results;
    const startItemNum = perPage * page + 1;
    const endItemNum = perPage * (page + 1);
    const total = conversationHistory?.data?.totalResults;

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            conversationApi = new ConversationApi(apiConfig);
                            const response = await conversationApi.getConversationsForSeller();
                            setConversationHistory(response);
                            setConversationHistoryLoading(false);
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error fetching seller messages.',
                                contextKey: 'Messages',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    return (
        <>
            <BSCHeaderBlock align="center" label="Messages" size18 />
            {!isMobile && (
                <>
                    <Grid container direction="row" justifyContent="space-between" className={classes.wrapper}>
                        <div className={classes.menu}>
                            <BSCTypography
                                className={classes.headerLabel}
                                label={`Viewing ${
                                    (total ?? 0) > 0 ? startItemNum + '-' + (endItemNum > total ? total : endItemNum) : '0'
                                } of ${(total ?? 0).toString()} Messages`}
                            />
                        </div>
                        <div className={classes.menu}>
                            <BSCTypography className={classes.marginRight} label="Results Per Page" />
                            <BSCSelect selectedSortValue={perPage} onSortChange={setPerPage}>
                                <MenuItem value={10}>
                                    <BSCTypography label="10" />
                                </MenuItem>
                                <MenuItem value={20}>
                                    <BSCTypography label="20" />
                                </MenuItem>
                                <MenuItem value={30}>
                                    <BSCTypography label="30" />
                                </MenuItem>
                            </BSCSelect>
                        </div>
                    </Grid>
                </>
            )}
            <SellerStickyHeadTable
                page={page}
                setPage={setPage}
                msgRef={msgRef}
                sizePerPage={perPage}
                conversationHistoryResults={conversationHistoryResults}
                loading={conversationHistoryLoading}
            />
        </>
    );
}

export default SellersMessageList;
