import Normalizer from '../../common/normalizer/normalizer';

const SliderClient = {
    getSliderItems: async () => {
        const basePath = Normalizer.getS3ImagePath('/static/mp_sliders.json');
        const data = await fetch(basePath).then(res => res.json());
        return data;
    },
};

export default SliderClient;
