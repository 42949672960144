import React from 'react';
import {memo, useCallback, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles, Theme} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import BSCTypography from '../../../common/bscTypography/bscTypography';
import {Box, Drawer, MenuItem} from '@material-ui/core';
import {ChevronLeft, Close} from '@material-ui/icons';
import BSCLoading from '../../../common/bscLoading/bscLoading';
import {ReactComponent as BSCBuyersLogo} from '../../../../svgs/bscSellers.svg';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import SubNavContent from '../sellersSubNavContent';
import BSCScroller from '../../../common/bscScroller/bscScroller';
import useReduxUser from '../../../hooks/redux/user/useReduxUser';
import BSCDivider from '../../../common/bscDivider/bscDivider';
import {SignOutButton} from '../../../users/signOutButton';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import BSCButton from '../../../common/bscButton/bscButton';
import {SellerSignInButton} from '../../../users/sellerSignInButton';
import {Skeleton} from '@material-ui/lab';
// import BSCImage from '../../../common/bscImage/bscImage';
import {useMsal} from '@azure/msal-react';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {AccountApi} from '../../../../client/openapitools/seller/api';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {Configuration} from '../../../../client/openapitools/seller/configuration';
import useReduxToast from '../../../hooks/redux/toast/useReduxToast';

const useStyles = makeStyles((theme: Theme) => ({
    headerContainer: {
        background: theme.palette.primary.main,
        position: 'sticky',
        left: 0,
        top: -1,
        zIndex: 10,
    },
    headerWrap: {
        maxWidth: 1400,
        margin: 'auto',
        // borderTop: 'solid #000 4px',
    },
    menuIcon: {
        padding: theme.spacing(1, 0.5),
    },
    logoWrapper: {
        position: 'absolute',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    capitalCase: {
        textTransform: 'none!important' as 'none',
    },
    logo: {
        height: 50,
        width: 85,
        ['&:hover']: {
            cursor: 'pointer',
        },
    },
    searchInput: {
        flexGrow: 2,
        width: '100%',
    },
    searchInputStyleProp: {
        background: theme.palette.common.white,
        borderRadius: 4,
        width: '100%',
    },
    sellersPortal: {
        color: theme.palette.common.white,
        textDecoration: 'none',
    },
    sellersLinkPortal: {
        textDecoration: 'none',
        marginRight: 12,
    },
    menuLeft: {
        zIndex: 1,
    },
    menuRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        zIndex: 1,
    },
    menuItem: {
        color: theme.palette.common.white,
        background: theme.palette.common.black,
        boxShadow:
            '0px 1.91111px 3.82222px rgba(0, 0, 0, 0.25), inset 0px -1.91111px 3.82222px rgba(0, 0, 0, 0.25), inset 0px 3.82222px 3.82222px rgba(255, 255, 255, 0.24)',
        borderRadius: 4,
        textAlign: 'center',
        padding: theme.spacing(2, 0),
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        ...theme.typography.button,
        fontSize: theme.typography.body2.fontSize,
        textTransform: 'inherit',
    },
    mobileSubNavContainer: {
        background: theme.palette.primary.main,
        height: '100%',
    },
    menuTitle: {
        position: 'relative',
        color: theme.palette.common.white,
        padding: theme.spacing(2, 0),
        background: theme.palette.primary.main,
        textAlign: 'center',
    },
    chevronLeft: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    mobileSubNavWrap: {
        padding: theme.spacing(0, 1),
    },
    typography: {
        color: theme.palette.common.white,
    },
    drawer: {
        width: '100%',
    },
}));

interface MobileNavProps {
    //TODO: Fix any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menus: undefined;
    menusLoading?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    userMenuLoading?: boolean;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    userMenu: undefined;
    onPortalChange: () => void;
}

const MenuStepEnum = {
    // MAIN_MENU: 0,
    SPORTS: 0,
    SUB_NAV: 1,
};

function MobileDrawer({menus, open, onClose, menusLoading, setCurrentMenuStep, currentMenuStep}) {
    const classes = useStyles();
    //const [currentMenuStep, setCurrentMenuStep] = useState(0);
    const [selectedMenu, setSelectedMenu] = useState(null);

    useEffect(() => {
        if (open) {
            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('body').style.overflow = null;
        }
    }, [open]);

    return (
        <Drawer open={open} onClose={onClose} anchor="left" className={classes.drawer} PaperProps={{className: classes.drawer}}>
            <div className={classes.mobileSubNavContainer}>
                <div className={classes.menuTitle}>
                    {currentMenuStep > MenuStepEnum.SPORTS && (
                        <IconButton className={classes.chevronLeft} onClick={() => setCurrentMenuStep(s => s - 1)}>
                            <ChevronLeft fontSize="large" htmlColor="white" />
                        </IconButton>
                    )}
                    <BSCTypography variant="h2" label={currentMenuStep === 0 ? "Seller's Locker" : selectedMenu?.title} />
                    <IconButton className={classes.closeButton} onClick={onClose}>
                        <Close fontSize="large" htmlColor="white" />
                    </IconButton>
                </div>
                <div className={classes.mobileSubNavWrap}>
                    <BSCScroller maxHeight="calc(100vh - 70px)">
                        <div>
                            {currentMenuStep === MenuStepEnum.SPORTS && (
                                <>
                                    <BSCLoading loading={menusLoading} />
                                    {menus?.categories?.map(menu => (
                                        <div key={menu.order}>
                                            {menu?.menus?.length > 0 && (
                                                <MenuItem
                                                    key={menu.order}
                                                    className={classes.menuItem}
                                                    onClick={() => {
                                                        setCurrentMenuStep(MenuStepEnum.SUB_NAV);
                                                        setSelectedMenu(menu);
                                                    }}
                                                >
                                                    {menu.title}
                                                </MenuItem>
                                            )}
                                            {menu?.menus?.length === 0 && (
                                                <MenuItem
                                                    key={menu.order}
                                                    component={Link}
                                                    to={menu.url}
                                                    onClick={onClose}
                                                    className={classes.menuItem}
                                                >
                                                    {menu.title}
                                                </MenuItem>
                                            )}
                                        </div>
                                    ))}
                                </>
                            )}
                            {currentMenuStep === MenuStepEnum.SUB_NAV && <SubNavContent menus={selectedMenu?.menus} onClose={onClose} />}
                        </div>
                    </BSCScroller>
                </div>
            </div>
        </Drawer>
    );
}
//eslint-disable-next-line @typescript-eslint/no-unused-vars
function MobileNav({menus, menusLoading = false, userMenu, userMenuLoading = false, onPortalChange}: MobileNavProps) {
    const classes = useStyles();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    let sellerAccountApi = new AccountApi(apiAuthConfig);
    // const {data: dashboardInformation, loading: dashboardInformationLoading} = useQuery(sellerAccountApi.getSellerInfo, []);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dashboardInformation, setDastboardInformation] = useState(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dashboardInformationLoading, setDashboardInformationLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [accountInfoOpen, setAccountInfoOpen] = useState(false);
    const history = useHistory();
    const {isAuth, userState} = useReduxUser();
    const [currentMenuStep, setCurrentMenuStep] = useState(0);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    //Dont need this here.  copy pasta error
    //const {cartState} = useReduxCart();

    // const launchPromo = () => {
    //     history.push('/sellers/promo');
    // };

    const handleClose = useCallback(() => {
        setOpen(false);
        setAccountInfoOpen(false);
    }, []);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                    try {
                        sellerAccountApi = new AccountApi(apiConfig);
                        const response = await sellerAccountApi.getSellerInfo();
                        setDastboardInformation(response);
                        setDashboardInformationLoading(false);
                    } catch (error) {
                        console.log(error);
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    console.log(error);
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    return (
        <>
            <div className={classes.headerContainer}>
                <div className={classes.headerWrap}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid className={classes.menuLeft}>
                            <IconButton
                                onClick={() => {
                                    setCurrentMenuStep(0);
                                    setOpen(s => !s);
                                }}
                                className={classes.menuIcon}
                            >
                                <MenuIcon htmlColor="white" fontSize={'large'} />
                            </IconButton>
                        </Grid>
                        <div className={classes.logoWrapper}>
                            <BSCBuyersLogo className={classes.logo} onClick={() => history.push('/sellers')} />
                        </div>
                        <Grid className={classes.menuRight}>
                            <PersonOutlineOutlinedIcon onClick={() => setAccountInfoOpen(true)} htmlColor="white" fontSize="large" />
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* {(window.location.pathname === '/sellers' || window.location.pathname === '/sellers/home') && (
                <div className={classes.headerWrap}>
                    {dashboardInformation && (
                        <>
                            <BSCImage
                                src={process.env.PUBLIC_URL + '/img/SummerSellerbration-Mobile-Payout.png'}
                                style={{borderTop: 'solid 4px #000'}}
                                onClick={launchPromo}
                            />
                            <Grid
                                item
                                xs={12}
                                style={{
                                    position: 'relative',
                                    top: '-10px',
                                    background: '#fff',
                                    borderTop: 'solid 4px #000',
                                    borderBottom: 'solid 4px #000',
                                }}
                            >
                                <div
                                    style={{
                                        textAlign: 'center',
                                        padding: '8px',
                                    }}
                                >
                                    <BSCTypography size18 style={{display: 'inline-block'}}>
                                        Your Earnings:&nbsp;
                                    </BSCTypography>
                                    <BSCTypography size18 style={{display: 'inline-block'}}>
                                        {new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        }).format(dashboardInformation?.data?.profile?.credits)}
                                    </BSCTypography>
                                </div>
                            </Grid>
                        </>
                    )}
                </div>
            )} */}
            {
                <Drawer
                    PaperProps={{style: {maxWidth: '90%'}}}
                    anchor={'right'}
                    open={accountInfoOpen}
                    onClose={() => setAccountInfoOpen(false)}
                >
                    <Box p={2}>
                        {userState?.firstName && (
                            <div>
                                <BSCTypography
                                    align="center"
                                    label={
                                        userState?.sellerProfile?.sellerStoreName
                                            ? `Welcome Back, ${userState?.sellerProfile?.sellerStoreName}!`
                                            : 'Hello!'
                                    }
                                    variant="h4"
                                />
                                <BSCDivider />
                            </div>
                        )}
                        {userMenuLoading && <Skeleton />}
                        {!userMenuLoading && userMenu && (
                            <>
                                {userMenu.dashboard.map((x, idx) => (
                                    <div key={`userMenu-${idx}`}>
                                        <BSCTypography
                                            variant="body2"
                                            align="center"
                                            onClick={() => {
                                                handleClose();
                                                history.push({
                                                    pathname: x['url'],
                                                });
                                            }}
                                            label={x['title']}
                                        />
                                        <BSCDivider />
                                    </div>
                                ))}
                            </>
                        )}
                        <div style={{textAlign: 'center'}}>
                            <BSCButton
                                style={{padding: 0}}
                                color="secondary"
                                variant="text"
                                align="center"
                                component={Link}
                                onClick={onPortalChange}
                                to="/"
                            >
                                <BSCTypography
                                    align="center"
                                    variant="body2"
                                    className={classes.capitalCase}
                                    label="Return to Marketplace"
                                />
                            </BSCButton>
                        </div>
                        <BSCDivider />
                        <div style={{textAlign: 'center'}}>
                            {isAuth ? (
                                <SignOutButton style={{padding: 0}} className={classes.capitalCase} size16 />
                            ) : (
                                <SellerSignInButton
                                    style={{padding: 0}}
                                    userMenuLoading={userMenuLoading}
                                    className={classes.capitalCase}
                                    userMenu={userMenu}
                                />
                            )}
                        </div>
                    </Box>
                </Drawer>
            }
            <MobileDrawer
                open={open}
                menus={menus}
                onClose={handleClose}
                menusLoading={menusLoading}
                currentMenuStep={currentMenuStep}
                setCurrentMenuStep={setCurrentMenuStep}
            />
        </>
    );
}

export default memo(MobileNav);
