/* eslint-disable @typescript-eslint/no-unused-vars */
import {Redirect, Route} from 'react-router';
import {WithAuthHOCProps} from '../../@types/withAuth';
import {useAuthFunctions, useAuthState} from '../../AuthContext';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../authConfig';
import useReduxToast from '../../features/hooks/redux/toast/useReduxToast';

function withAuth(Component: typeof Route) {
    return ({protectedRoute, component, ...otherProps}: WithAuthHOCProps) => {
        const {instance} = useMsal();
        const {isLoginInProgress} = useAuthState();
        const isAuthenticated = useIsAuthenticated();
        const {addToast} = useReduxToast();
        const authFunc = useAuthFunctions();

        if (isLoginInProgress) {
            return <div>Loading</div>;
        }

        if (protectedRoute) {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        authFunc.setAccessToken(response.accessToken);
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            } else {
                return <Redirect push to="/" />;
            }
        }

        return <Component component={component} {...otherProps} />;
    };
}

export default withAuth;
