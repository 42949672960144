import React, {useCallback, useEffect} from 'react';
import {Switch, Route, useParams, Redirect} from 'react-router';
import {useHistory} from 'react-router-dom';
import {makeStyles, Theme} from '@material-ui/core/styles';
import BSCTabs from '../../../../features/common/bscTabs/bscTabs';
import BSCTab from '../../../../features/common/bscTab/bscTab';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import Profile from './tabs/profile';
import Feedback from './tabs/feedback';
import Faq from './tabs/faq';
import BePro from './tabs/bePro';

const tabEnums = {
    profile: {path: 'profile', key: 0},
    feedback: {path: 'feedback', key: 1},
    faq: {path: 'faq', key: 2},
    bepro: {path: 'bepro', key: 3},
};

const useStyles = makeStyles((theme: Theme) => ({
    cap: {
        width: '100%',
        overflowX: 'auto',
    },
    tabWrapper: {
        minWidth: 1200,
        [theme.breakpoints.down('xs')]: {
            minWidth: 323,
        },
        marginBottom: theme.spacing(2),
    },
    tabItem: {
        fontSize: theme.typography.body2.fontSize,
    },
}));

function SellerDashboard() {
    const classes = useStyles();
    const history = useHistory();
    const {selectedTab}: {selectedTab: string} = useParams();
    const activeTab = tabEnums?.[selectedTab.toLowerCase()]?.key ?? null;

    useEffect(() => {
        if (!activeTab) {
            history.push('/sellers/dashboard/profile');
        }
    }, [activeTab, history]);

    const handleTabChange = useCallback(
        (newTabKey: number) => {
            let newActiveTab = tabEnums.profile.path;
            for (const tabKey in tabEnums) {
                const item = tabEnums[tabKey];
                newActiveTab = item.key === newTabKey ? item.path : newActiveTab;
            }
            history.push(`/sellers/dashboard/${newActiveTab}`);
        },
        [history]
    );

    return (
        <BSCContainer variant="page">
            <div className={classes.cap}>
                <div className={classes.tabWrapper}>
                    <BSCTabs activeTab={activeTab} onTabChange={handleTabChange} variant="fullWidth">
                        <BSCTab label="Seller Profile" value={tabEnums.profile.key} />
                        <BSCTab label="Feedback" value={tabEnums.feedback.key} />
                        <BSCTab label="FAQ" value={tabEnums.faq.key} />
                        {/* <BSCTab label="Become a pro!" value={tabEnums.bepro.key} /> */}
                    </BSCTabs>
                </div>
            </div>
            <Switch>
                <Redirect push from="/sellers/dashboard/" to="/sellers/dashboard/profile/" exact />
                <Route path={'/sellers/dashboard/profile'}>
                    <Profile />
                </Route>
                <Route exact path={'/sellers/dashboard/feedback'}>
                    <Feedback />
                </Route>
                <Route exact path={'/sellers/dashboard/faq'}>
                    <Faq />
                </Route>
                <Route exact path={'/sellers/dashboard/bepro'}>
                    <BePro />
                </Route>
            </Switch>
        </BSCContainer>
    );
}

export default SellerDashboard;
