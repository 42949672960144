import React from 'react';
import {Switch, Route} from 'react-router-dom';
import SellersRoot from '../sellersRoot';
import withAuth from '../../../features/HOCs/withAuth';
import {WithAuthHOCProps} from '../../../@types/withAuth';
import SellersInventory from '../pages/inventory/inventory';
import SellYourCard from '../pages/sellYourCard/';
import SellersOrders from '../pages/orders/orders';
import SellersOrderDetails from '../pages/orderDetails/orderDetails';
import SellersSalesSummary from '../pages/salesSummary/salesSummary';
import SellersMessages from '../pages/messages';
import SellerDashboard from '../pages/sellerDashboard';
import SellerSignup from '../pages/sellerSignup';
import SellerOffers from '../pages/offers';
import SellerPaymentSetting from '../pages/sellerPaymentSetting';
import SellerPayments from '../pages/sellerPayments';
import PackingSlip from '../pages/orderDetails/packingSlip';
import SellerBulkUpload from '../pages/bulkUpload';
import BSCPromo from '../pages/bscPromo';

const RouteWithAuth = withAuth(Route);

const routes = [
    {
        component: () => <SellerSignup />,
        path: '/sellers/signup',
        protectedRoute: true,
    },
    {
        component: () => <SellerPaymentSetting />,
        path: '/sellers/payment-setting',
        protectedRoute: true,
    },
    {
        component: () => <SellerPayments />,
        path: '/sellers/payments',
        protectedRoute: true,
    },
    {
        component: () => <SellersInventory />,
        path: '/sellers/inventory',
        protectedRoute: true,
    },
    {
        component: () => <SellYourCard />,
        path: '/sellers/sell-your-card/:selectedTab/:cardId',
        protectedRoute: true,
    },
    {
        component: () => <SellersOrderDetails />,
        path: '/sellers/orders/order-detail',
        protectedRoute: true,
    },
    {
        component: () => <PackingSlip />,
        path: '/sellers/orders/packingSlip',
        protectedRoute: true,
    },
    {
        component: () => <SellersOrders />,
        path: '/sellers/orders',
        protectedRoute: true,
    },
    {
        component: () => <SellerOffers />,
        path: '/sellers/offers',
        protectedRoute: true,
    },
    {
        component: () => <SellersSalesSummary />,
        path: '/sellers/sales-summary',
        protectedRoute: true,
    },
    {
        component: () => <SellersMessages />,
        path: '/sellers/messages',
        protectedRoute: true,
    },
    {
        component: () => <SellerBulkUpload />,
        path: '/sellers/bulk-upload',
        protectedRoute: true,
    },
    {
        component: () => <SellerDashboard />,
        path: '/sellers/dashboard/:selectedTab',
        protectedRoute: true,
    },
    {
        component: () => <BSCPromo />,
        path: '/sellers/promo',
        protectedRoute: true,
    },
    {
        component: () => <SellersRoot />,
        path: '/sellers',
        protectedRoute: true,
    },
    {
        component: () => <h1>404 Page Not Found</h1>,
        protectedRoute: false,
    },
];

function SellerLockerRoutes() {
    return (
        <Switch>
            {routes.map((route: WithAuthHOCProps, index: number) => (
                <RouteWithAuth key={index} {...route} protectedRoute={route.protectedRoute} />
            ))}
        </Switch>
    );
}

export default SellerLockerRoutes;
