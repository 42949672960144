import {Switch, Route} from 'react-router-dom';
import Home from '../pages/home/home';
import SearchPage from '../pages/search/SearchPage';
import ProductPage from '../pages/productPage/productPage';
import CartSummary from '../pages/cart/cartSummary';
import CheckOutCart from '../pages/cart/checkoutCart';
import Dashboard from '../pages/dashboard/dashboard';
import withAuth from '../../../features/HOCs/withAuth';
import {WithAuthHOCProps} from '../../../@types/withAuth';
import ThankYou from '../pages/cart/thankYou';
import EditCart from '../pages/cart/editCart';
import ContactUs from '../pages/help/ContactUs';
import Faq from '../pages/dashboard/components/faq';
import BSCTerms from '../../../bscFooter/bscTerms';
import BSCPrivacy from '../../../bscFooter/bscPrivacy';
import NotFound from '../../../features/navigation/NotFound';
import AboutUs from '../pages/home/aboutUs';
import Seller from '../pages/seller';
import BSCPromo from '../pages/bscPromo';

const RouteWithAuth = withAuth(Route);

const routes = [
    {
        component: () => <Home />,
        exact: true,
        path: '/',
        protectedRoute: false,
    },
    {
        component: () => <SearchPage />,
        exact: true,
        path: '/search',
        protectedRoute: false,
    },
    {
        component: () => <AboutUs />,
        exact: true,
        path: '/aboutUs',
        protectedRoute: false,
    },
    {
        component: () => <ProductPage />,
        path: '/product/:productId',
        protectedRoute: false,
    },
    {
        component: () => <CartSummary />,
        path: '/cart/summary',
        protectedRoute: false,
    },
    {
        component: () => <CheckOutCart />,
        path: '/cart/checkout',
        protectedRoute: false,
        exact: true,
    },
    {
        component: () => <EditCart />,
        path: '/cart/edit',
        protectedRoute: false,
        exact: true,
    },
    {
        component: () => <ThankYou />,
        path: '/cart/thank-you',
        protectedRoute: false,
        exact: true,
    },
    {
        component: () => <Dashboard />,
        path: '/dashboard/:selectedTab',
        protectedRoute: true,
    },
    {
        component: () => <ContactUs />,
        path: '/support',
        protectedRoute: false,
    },
    {
        component: () => <Faq />,
        path: '/faq',
        protectedRoute: false,
    },
    {
        component: () => <BSCTerms />,
        path: '/terms',
        protectedRoute: false,
    },
    {
        component: () => <BSCPromo />,
        path: '/promo',
        protectedRoute: false,
    },
    {
        component: () => <BSCPrivacy />,
        path: '/privacy',
        protectedRoute: false,
    },
    {
        component: () => <Seller />,
        path: '/Seller/:sellerId/:sellerName',
        protectedRoute: false,
    },
    {
        component: () => <NotFound />,
        protectedRoute: false,
    },
];

function MarketplaceRoutes() {
    return (
        <Switch>
            {routes.map((route: WithAuthHOCProps, index: number) => (
                <RouteWithAuth key={index} {...route} protectedRoute={route.protectedRoute} />
            ))}
        </Switch>
    );
}

export default MarketplaceRoutes;
