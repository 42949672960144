import {memo} from 'react';
import DesktopNav from './desktopNav/sellersDesktopNav';
import MobileNav from './mobileNav/sellersMobileNav';
import useScreenSnap from '../../../packages/core/src/hooks/useScreenSnap';
import {useQuery} from '../../../packages/core/src/hooks/useQuery';
import {useHistory} from 'react-router';
import SellersNavigationClient from '../../../client/sellers/navigation/SellersNavigationClient';

function SellersLockerHeader() {
    const {isTablet, isMobile} = useScreenSnap();
    const history = useHistory();
    const {loading: menusLoading, data: menus} = useQuery(() => SellersNavigationClient.getNav(), []);
    const {loading: userMenuLoading, data: userMenu} = useQuery(() => SellersNavigationClient.getUserMenus(), []);
    const handlePortalChange = () => {
        history.push('/');
    };

    return !isTablet && !isMobile ? (
        <DesktopNav
            menus={menus}
            menusLoading={menusLoading}
            userMenuLoading={userMenuLoading}
            userMenu={userMenu}
            onPortalChange={handlePortalChange}
        />
    ) : (
        <MobileNav
            menus={menus}
            menusLoading={menusLoading}
            userMenuLoading={userMenuLoading}
            userMenu={userMenu}
            onPortalChange={handlePortalChange}
        />
    );
}

export default memo(SellersLockerHeader);
