import {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router';
import BSCLoading from '../../features/common/bscLoading/bscLoading';
import SellersPortal from './pages/sellersPortal/sellersPortal';
import {useSelector} from 'react-redux';
import {RootState} from '../../redux/reducers';

const SellersRoot = () => {
    const [user, setUser] = useState(null);
    const sellerState = useSelector((state: RootState) => state.seller);
    const isSellerSignup = useRouteMatch('/sellers/signup');
    const history = useHistory();

    useEffect(() => {
        if (sellerState) {
            setUser(sellerState.sellersLockerUser);
        }
    }, [sellerState]);

    if (user === null) {
        return <BSCLoading loading={true}></BSCLoading>;
    }

    if (sellerState.hasVerifiedMicroDeposits) {
        return <SellersPortal />;
    }

    if (user?.sellerProfile?.applicationStatus === 'completed') {
        return <SellersPortal />;
    } else if (user?.sellerProfile?.applicationStatus === 'pending' && !isSellerSignup) {
        history.replace('/sellers/signup');
        return <></>;
    } else if (user?.sellerProfile?.applicationStatus === 'contact') {
        //TODO: validate the appication status that is a partial and needs to be sent to contact us page.
        history.replace('/support');
        return <></>;
    } else if (!isSellerSignup) {
        history.replace('/sellers/signup');
        return <></>;
    }
};

export default SellersRoot;
