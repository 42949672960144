import {SearchSorts} from '../../../../enums/filterEnum';
import {searchCreator} from '../../../../util/searchCreator';
import useApi from '../../../../features/hooks/redux/apiConfig/useApi';

interface MarketPlaceSearchArgs {
    terms: {
        sort?: SearchSorts;
        p?: string | null;
        q?: string | null;
        pageNumber?: number;
        inStock?: boolean;
        condition?: object | null;
        priceRangeStart?: number | null;
        priceRangeEnd?: number | null;
    };
    listingSearch?: object | null;
    sellerKey: string;
}
const api = useApi();

const SearchShopResultsClient = {
    marketPlaceSearch: ({terms = {}, listingSearch = {}, sellerKey}: MarketPlaceSearchArgs) => {
        const {queryString, postObj} = searchCreator({terms, listingSearch, sellerKey});
        return api.searchApi.marketplace.getResults(postObj, queryString);
    },
};

export default SearchShopResultsClient;
