/* eslint-disable @typescript-eslint/no-unused-vars */
import {useState} from 'react';
import {Box, MobileStepper, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {CarouselItemsResult} from '../../../../../client/s3/mpCarousel/carouselClient';
import {useStyles} from './styles';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import BSCSlider from '../../../../../features/common/bscSlider/bscSlider';
import BSCImage from '../../../../../features/common/bscImage/bscImage';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import Normalizer from '../../../../../client/common/normalizer/normalizer';
import {useHistory} from 'react-router-dom';
import useReduxUser from '../../../../../features/hooks/redux/user/useReduxUser';
import {useAuthFunctions} from '../../../../../AuthContext';
interface HomeCarouselProps {
    carouselItems: CarouselItemsResult | null;
}

const HomeCarousel = ({carouselItems}: HomeCarouselProps) => {
    const {isTablet, isMobile} = useScreenSnap();
    const classes = useStyles();
    const history = useHistory();
    const {userState} = useReduxUser();
    const authFunc = useAuthFunctions();
    const [activeStep, setActiveStep] = useState(0);

    function handleOnClick(slugTarget) {
        if (userState) {
            if (userState.roleId === '2') {
                //is seller
                history.push(slugTarget);
            } else if (userState.roleId === '1') {
                //is buyer
                history.push('/sellers/signup');
            }
        } else {
            authFunc.login();
        }
    }

    return (
        <Box className={classes.root}>
            {carouselItems && (
                <>
                    <BSCSlider className={classes.carouselWrapper} onSlide={setActiveStep} slideColumns={1} slidesPerNext={1} spacing={0}>
                        {carouselItems.bannerItems.map((item, index) => (
                            <Box className={classes.carouselImgWrapper} key={index} to={item.slug} component={Link}>
                                <BSCImage
                                    className={classes.image}
                                    style={{height: '100%', objectFit: 'cover !important', width: 'fit-content'}}
                                    src={Normalizer.getS3ImagePath(
                                        isMobile ? item.images.mobile : isTablet ? item.images.tablet : item.images.desktop
                                    )}
                                    alt={item.imgAlt}
                                />
                                <Box className={classes.carouselImgOverlay}>
                                    <Typography className={classes.headline} component={isMobile ? 'h3' : 'h2'}>
                                        {item.headerText}
                                    </Typography>
                                    {item?.buttonText !== '' && (
                                        <BSCButton className={classes.carouselBtn} onClick={e => handleOnClick(item.slug)} color="primary">
                                            {item.buttonText}
                                        </BSCButton>
                                    )}
                                </Box>
                            </Box>
                        ))}
                    </BSCSlider>
                    <MobileStepper
                        classes={{dotActive: classes.dotActive}}
                        activeStep={activeStep}
                        backButton={null}
                        className={classes.stepper}
                        nextButton={null}
                        position="static"
                        steps={carouselItems.bannerItems.length}
                        variant="dots"
                    />
                </>
            )}
        </Box>
    );
};

export default HomeCarousel;
