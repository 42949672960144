/* eslint-disable no-var */
import React from 'react';
import * as constants from '../../../../shared/constants';
import {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles, Theme} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AsyncSearchInput from '../../../asyncSearchInput/asyncSearchInput';
import CartButton from '../../../cartButton/cartButton';
import BSCTypography from '../../../common/bscTypography/bscTypography';
import {Box, Drawer, MenuItem} from '@material-ui/core';
import {ChevronLeft, Close} from '@material-ui/icons';
import BSCLoading from '../../../common/bscLoading/bscLoading';
import {ReactComponent as BSCBuyersLogo} from '../../../../svgs/bscBuyers.svg';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import SubNavContent from '../subNavContent';
import {SignOutButton} from '../../../users/signOutButton';
import {SignInButton} from '../../../users/signInButton';
import BSCDivider from '../../../common/bscDivider/bscDivider';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import BSCButton from '../../../common/bscButton/bscButton';
import {Menus} from '../../../../@types/menus';
import {Skeleton} from '@material-ui/lab';
import StorefrontIcon from '@material-ui/icons/Storefront';
import {RootState} from '../../redux/reducers';
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from '@azure/msal-react';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {Configuration, ConversationApi} from '../../../../client/openapitools/common';
import useReduxToast from '../../../hooks/redux/toast/useReduxToast';
import {UserApi} from '../../../../client/openapitools/marketplace/api';

const useStyles = makeStyles((theme: Theme) => ({
    headerContainer: {
        background: 'black',
    },
    headerWrap: {
        maxWidth: 1400,
        margin: 'auto',
    },
    logo: {
        height: 50,
        width: 85,
        //This is the width of the Icon Button Divs.  Yes its a hack but it gets things centered correctly
        marginLeft: '20%',
        ['&:hover']: {
            cursor: 'pointer',
        },
    },

    menuItem: {
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        boxShadow:
            '0px 1.91111px 3.82222px rgba(0, 0, 0, 0.25), inset 0px -1.91111px 3.82222px rgba(0, 0, 0, 0.25), inset 0px 3.82222px 3.82222px rgba(255, 255, 255, 0.24)',
        borderRadius: 4,
        textAlign: 'center',
        padding: theme.spacing(2, 0),
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        ...theme.typography.button,
        textTransform: 'inherit',
        fontSize: theme.typography.body2.fontSize,
    },
    mobileSubNavContainer: {
        background: theme.palette.common.black,
        height: '100%',
        maxHeight: '100%',
    },
    menuTitle: {
        position: 'relative',
        color: theme.palette.common.white,
        padding: theme.spacing(2, 0),
        background: 'linear-gradient(180deg, #41474D -0.23%, #030303 27.26%)',
        textAlign: 'center',
    },
    chevronLeft: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    mobileSubNavWrap: {
        padding: theme.spacing(0, 1),
        // maxHeight: 'calc(90vh-70px)',
        overflowY: 'scroll',
        height: 'calc(100% - 70px)',
    },
    drawer: {
        width: '100%',
    },
    normal: {
        fontStyle: 'normal',
    },
    capitalCase: {
        textTransform: 'none!important' as 'none',
    },
    fillBlack: {
        fill: 'black',
    },

    largeIcon: {
        //     height: 34,
        //  width: 34,
        marginRight: '-15px',
        '& svg': {
            height: 32,
            width: 32,
        },
    },
    lineHeight15: {
        lineHeight: 1.5,
    },
    messagesBadge: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.common.white,
        position: 'absolute',
        top: 8,
        left: -2,
        zIndex: 1,
        background: theme.palette.primary.main,
        padding: 5,
        width: 10,
        height: 10,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
    },
    messagesBadgeHidden: {
        display: 'none',
    },
    menuMessagesBadge: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.common.white,
        position: 'absolute',
        top: 2,
        right: 8,
        zIndex: 1,
        background: theme.palette.primary.main,
        padding: 5,
        width: 10,
        height: 10,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
    },
    navLinks: {
        color: 'transparent',
    },
}));

interface MobileNavProps {
    menus: Menus[];
    menusLoading?: boolean;
    userMenuLoading?: boolean;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    userMenu: undefined;
}

const MenuStepEnum = {
    SPORTS: 0,
    SUB_NAV: 1,
};

function MobileDrawer({menus, open, onClose, menusLoading, setCurrentMenuStep, currentMenuStep}) {
    const classes = useStyles();
    const [selectedMenu, setSelectedMenu] = useState(null);

    useEffect(() => {
        if (open) {
            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('body').style.overflow = null;
        }
    }, [open]);

    const [potentialNavigationRoutes, setPotentialNavigationRoutes] = useState([]);
    useEffect(() => {
        if (menus && menus.categories && menus.categories.length > 0) {
            var tempMenus = [];
            for (let x = 0; x < menus.categories.length; x++) {
                tempMenus.push({
                    focus: menus.categories[x].title,
                    url: menus.categories[x].url,
                });
                for (let y = 0; y < menus.categories[x].menus.length; y++) {
                    tempMenus.push({
                        focus: menus.categories[x].title + ' - ' + menus.categories[x].menus[y].title,
                        url: menus.categories[x].menus[y].url,
                    });
                    for (let z = 0; z < menus.categories[x].menus[y].links.length; z++) {
                        tempMenus.push({
                            focus:
                                menus.categories[x].title +
                                ' - ' +
                                menus.categories[x].menus[y].title +
                                ' - ' +
                                menus.categories[x].menus[y].links[z].title,
                            url: menus.categories[x].menus[y].links[z].url,
                        });
                    }
                }
            }
            setPotentialNavigationRoutes(tempMenus);
        }
    }, [menus]);

    return (
        <>
            <Drawer open={open} onClose={onClose} anchor="left" className={classes.drawer} PaperProps={{className: classes.drawer}}>
                <div className={classes.mobileSubNavContainer}>
                    <div className={classes.menuTitle}>
                        {currentMenuStep > MenuStepEnum.SPORTS && (
                            <IconButton className={classes.chevronLeft} onClick={() => setCurrentMenuStep(s => s - 1)}>
                                <ChevronLeft fontSize="large" htmlColor="white" />
                            </IconButton>
                        )}
                        <BSCTypography
                            variant={currentMenuStep === 0 ? 'h2' : 'h3'}
                            capitalize
                            label={currentMenuStep === 0 ? 'Sports' : selectedMenu?.title.toLowerCase()}
                        />
                        <IconButton className={classes.closeButton} onClick={onClose}>
                            <Close fontSize="large" htmlColor="white" />
                        </IconButton>
                    </div>
                    <div className={classes.mobileSubNavWrap}>
                        <div>
                            {currentMenuStep === MenuStepEnum.SPORTS && (
                                <>
                                    <BSCLoading loading={menusLoading} />
                                    {menus?.categories?.map(menu => (
                                        <MenuItem
                                            key={menu.order}
                                            className={classes.menuItem}
                                            onClick={() => {
                                                setCurrentMenuStep(MenuStepEnum.SUB_NAV);
                                                setSelectedMenu(menu);
                                            }}
                                        >
                                            {menu.title}
                                        </MenuItem>
                                    ))}
                                </>
                            )}

                            {currentMenuStep === MenuStepEnum.SUB_NAV && (
                                <SubNavContent
                                    menus={selectedMenu?.menus}
                                    menuId={selectedMenu?.order}
                                    onGoatClick={onClose}
                                    url={selectedMenu?.url}
                                />
                            )}
                        </div>
                        {/* </BSCScroller> */}
                    </div>
                </div>
            </Drawer>
            <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', top: -500, left: -1500}}>
                    {potentialNavigationRoutes &&
                        potentialNavigationRoutes.map((navRoute, index) => (
                            <a href={navRoute.url} key={index} className={classes.navLinks}>
                                {navRoute.focus}
                            </a>
                        ))}
                </div>
            </div>
        </>
    );
}

function MobileNav({menus, menusLoading = false, userMenuLoading, userMenu}: MobileNavProps) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [accountInfoOpen, setAccountInfoOpen] = useState(false);
    const {instance, inProgress} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const history = useHistory();
    const dispatch = useDispatch();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    // const {userState} = useReduxUser();
    const [userState, setUserState] = useState(null);
    const [currentMenuStep, setCurrentMenuStep] = useState(0);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    const handleClose = useCallback(() => {
        setOpen(false);
        setAccountInfoOpen(false);
    }, []);

    const [buyerMessagesCount, setBuyerMessagesCount] = useState('');
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.buyerMessagesCount !== '') {
                setBuyerMessagesCount(marketplaceState.buyerMessagesCount);
            } else {
                setBuyerMessagesCount('');
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const conversationApi = new ConversationApi(apiConfig);
                            conversationApi.getConversationsForBuyer().then(res => {
                                const messagesData = res?.data.results;
                                if (messagesData !== null) {
                                    const unreadMessages = messagesData.filter(msg => msg.read === false);
                                    let tempBuyersMessageCount = '';
                                    if (unreadMessages.length > 0) {
                                        tempBuyersMessageCount = unreadMessages.length + '';
                                    }
                                    setBuyerMessagesCount(tempBuyersMessageCount);
                                    dispatch({
                                        type: constants.BUYER_MESSAGES_COUNT,
                                        payload: {buyerMessagesCount: tempBuyersMessageCount},
                                    });
                                }
                            });
                            const userApi = new UserApi(apiConfig);
                            const response = await userApi.getUserProfile();
                            setUserState(response.data);
                        } catch (error) {
                            console.error('Error fetching user profile', error);
                            addToast({
                                severity: 'error',
                                message: 'Error fetching user profile',
                                contextKey: 'User Profile',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    return (
        <>
            <div className={classes.headerContainer}>
                <div className={classes.headerWrap}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <IconButton onClick={() => setOpen(s => !s)}>
                                <MenuIcon htmlColor="white" fontSize={'large'} />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <BSCBuyersLogo className={classes.logo} onClick={() => history.push('/')} />
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="flex-end" alignItems="center">
                                <Grid item style={{position: 'relative'}}>
                                    <div className={buyerMessagesCount !== '' ? classes.messagesBadge : classes.messagesBadgeHidden}>
                                        {buyerMessagesCount}
                                    </div>
                                    <IconButton onClick={() => setAccountInfoOpen(true)} className={classes.largeIcon}>
                                        <PersonOutlineOutlinedIcon htmlColor="white" />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <CartButton />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <AsyncSearchInput />
                        </Grid>
                    </Grid>
                </div>
            </div>
            {
                <Drawer
                    PaperProps={{style: {maxWidth: '90%'}}}
                    anchor={'right'}
                    open={accountInfoOpen}
                    onClose={() => setAccountInfoOpen(false)}
                >
                    <div align="center">
                        <Box p={2}>
                            {inProgress === 'none' && (
                                <>
                                    <AuthenticatedTemplate>
                                        <>
                                            {userState?.firstName && (
                                                <div>
                                                    <BSCTypography
                                                        variant="h4"
                                                        className={classes.lineHeight15}
                                                        align="center"
                                                        label={userState?.firstName ? `Welcome Back, ${userState?.firstName}!` : 'Hello!'}
                                                    />
                                                    <BSCDivider />
                                                </div>
                                            )}
                                            {userMenuLoading && <Skeleton />}
                                            {!userMenuLoading && userMenu && (
                                                <>
                                                    {userMenu.dashboard.map((x, idx) => (
                                                        <div key={`userMenu-${idx}`} style={{position: 'relative'}}>
                                                            <span
                                                                style={x['title'] === 'Messages' ? {display: 'flex'} : {display: 'none'}}
                                                                className={
                                                                    buyerMessagesCount !== ''
                                                                        ? classes.menuMessagesBadge
                                                                        : classes.messagesBadgeHidden
                                                                }
                                                            >
                                                                {buyerMessagesCount}
                                                            </span>
                                                            <BSCTypography
                                                                align="center"
                                                                variant="body2"
                                                                gutterBottom
                                                                size16
                                                                onClick={() => {
                                                                    handleClose();
                                                                    history.push({
                                                                        pathname: x['url'],
                                                                    });
                                                                }}
                                                                label={x['title']}
                                                            />
                                                            <BSCDivider />
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                            <div>
                                                <BSCButton
                                                    className={(classes.normal, classes.capitalCase)}
                                                    variant="text"
                                                    fullWidth
                                                    component={Link}
                                                    startIcon={<StorefrontIcon color="primary" />}
                                                    //Let sellers locker handle sign up / contact us / whatever
                                                    to={'/sellers'}
                                                >
                                                    <BSCTypography align="center" variant="body2" normal size16 label="Seller's Locker" />
                                                </BSCButton>
                                                <BSCDivider />
                                                <SignOutButton
                                                    fullWidth
                                                    align="center"
                                                    size16
                                                    variant="text"
                                                    className={classes.capitalCase}
                                                />
                                            </div>
                                        </>
                                    </AuthenticatedTemplate>
                                    <UnauthenticatedTemplate>
                                        <>
                                            <SignInButton
                                                userMenuLoading={userMenuLoading}
                                                userMenu={userMenu}
                                                className={classes.capitalCase}
                                                fullWidth
                                                align="center"
                                                variant="body2"
                                                color="textPrimary"
                                            />
                                        </>
                                    </UnauthenticatedTemplate>
                                </>
                            )}
                        </Box>
                    </div>
                </Drawer>
            }
            <MobileDrawer
                open={open}
                menus={menus}
                onClose={handleClose}
                currentMenuStep={currentMenuStep}
                setCurrentMenuStep={setCurrentMenuStep}
                menusLoading={menusLoading}
            />
        </>
    );
}

export default memo(MobileNav);
