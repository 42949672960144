import * as constants from '../../shared/constants';
import Normalizer from '../../client/common/normalizer/normalizer';

const initialState = {
    accessToken: '',
    isLoading: false,
    isProductSelected: false,
    isSellerSelected: false,
    stockImageFront: '',
    stockImageBack: '',
    productImageFront: '',
    productImageBack: '',
    sellerImageFront: '',
    sellerImageBack: '',
    sellerName: '',
    isVintage: false,
    isMobileSellActive: true,
    isMobileListActive: false,
    isMobileHistoryActive: false,
    sellerStorefront: {},
    sessionInStock: 'true',
    scrollableId: '',
    buyerMessagesCount: '',
    searchSortParam: '',
    breadcrumbsInStock: true,
    breadcrumbsSortOrder: undefined,
    breadcrumbsCardCondition: undefined,
    isDeviceFooterNavHomeActive: true,
    isDeviceFooterNavAccountActive: false,
    isDeviceFooterNavSearchActive: false,
    isDeviceFooterNavFAQsActive: false,
    isDeviceFooterNavSellingActive: false,
    isPriceRangeReset: false,
    showAllListings: false,
    hasNewCartQuantity: false,
};

const fetchSellerStorefrontFromLocalStorage = () => {
    if (localStorage.getItem('sellerStorefrontId') !== null) {
        return Object.assign(
            {},
            {
                sellerId: localStorage.getItem('sellerStorefrontId'),
                sellerName: localStorage.getItem('sellerStorefrontName'),
            }
        );
    } else {
        return {};
    }
};

const fetchSessionInStockFromLocalStorage = () => {
    if (localStorage.getItem('sessionInStock') !== null) {
        return localStorage.getItem('sessionInStock');
    }
    return false;
};

export default (state = initialState, action) => {
    switch (action?.type) {
        case constants.ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload.accessToken,
            };
        case constants.IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case constants.CARD_IMAGE_DEFAULT:
            return {
                ...state,
                stockImageFront: action.payload.imageFront,
                stockImageBack: action.payload.imageBack,
            };
        case constants.PRODUCT_IMAGE:
            return {
                ...state,
                isProductSelected: true,
                productImageFront: Normalizer.getS3ImagePath(action.payload.imageFront),
                productImageBack: Normalizer.getS3ImagePath(action.payload.imageBack),
                isSellerSelected: false,
                sellerImageFront: '',
                sellerImageBack: '',
                sellerName: '',
            };
        case constants.PRODUCT_RESET:
            return {
                ...state,
                isProductSelected: false,
            };
        case constants.SESSION_IN_STOCK:
            if (action.payload.sessionInStock === true) {
                localStorage.setItem('sessionInStock', 'true');
            } else {
                localStorage.setItem('sessionInStock', 'false');
            }
            return {
                ...state,
                sessionInStock: action.payload.sessionInStock,
            };
        case constants.SELLER_RESET:
            return {
                ...state,
                isSellerSelected: false,
                isVintage: false,
                sellerImageFront: '',
                sellerImageBack: '',
                sellerName: '',
            };
        case constants.SELLER_SELECTED:
            return {
                ...state,
                isSellerSelected: true,
                isVintage: action.payload.isVintage,
                sellerName: action.payload.sellerName,
                sellerImageFront: Normalizer.getS3ImagePath(action.payload.imageFront),
                sellerImageBack: Normalizer.getS3ImagePath(action.payload.imageBack),
            };
        case constants.NAVIGATE_MOBILE_SELL:
            return {
                ...state,
                isMobileSellActive: true,
                isMobileListActive: false,
                isMobileHistoryActive: false,
            };
        case constants.NAVIGATE_MOBILE_LIST:
            return {
                ...state,
                isMobileSellActive: false,
                isMobileListActive: true,
                isMobileHistoryActive: false,
            };
        case constants.NAVIGATE_MOBILE_HISTORY:
            return {
                ...state,
                isMobileSellActive: false,
                isMobileListActive: false,
                isMobileHistoryActive: true,
            };
        case constants.SET_MARKETPLACE_SCROLLABLE_ID:
            return {
                ...state,
                scrollableId: action.payload.scrollableId,
            };
        case constants.SELLER_STOREFRONT_SELECTED:
            localStorage.setItem('sellerStorefrontId', action.payload.sellerId);
            localStorage.setItem('sellerStorefrontName', action.payload.sellerName);
            return {
                ...state,
                sellerStorefront: Object.assign(
                    {},
                    {
                        sellerId: action.payload.sellerId,
                        sellerName: action.payload.sellerName,
                    }
                ),
            };
        case constants.SELLER_STOREFRONT_RESET:
            localStorage.removeItem('sellerStorefrontId');
            localStorage.removeItem('sellerStorefrontName');
            return {
                ...state,
                sellerStorefront: {},
            };
        case constants.BUYER_MESSAGES_COUNT:
            return {
                ...state,
                buyerMessagesCount: action.payload.buyerMessagesCount,
            };
        case constants.UPDATE_SEARCH_SORT_PARAM:
            return {
                ...state,
                searchSortParam: action.payload.searchSortParam,
            };
        case constants.BREADCRUMBS_CARD_CONDITION:
            return {
                ...state,
                breadcrumbsCardCondition: action.payload.breadcrumbsCardCondition,
            };
        case constants.BREADCRUMBS_IN_STOCK:
            return {
                ...state,
                breadcrumbsInStock: action.payload.breadcrumbsInStock,
            };
        case constants.BREADCRUMBS_SORT_ORDER:
            return {
                ...state,
                breadcrumbsSortOrder: action.payload.breadcrumbsSortOrder,
            };
        case constants.MOBILE_DEVICE_FOOTER_NAV_HOME:
            return {
                ...state,
                isDeviceFooterNavHomeActive: true,
                isDeviceFooterNavAccountActive: false,
                isDeviceFooterNavSearchActive: false,
                isDeviceFooterNavFAQsActive: false,
                isDeviceFooterNavSellingActive: false,
            };
        case constants.MOBILE_DEVICE_FOOTER_NAV_ACCOUNT:
            return {
                ...state,
                isDeviceFooterNavHomeActive: false,
                isDeviceFooterNavAccountActive: true,
                isDeviceFooterNavSearchActive: false,
                isDeviceFooterNavFAQsActive: false,
                isDeviceFooterNavSellingActive: false,
            };
        case constants.MOBILE_DEVICE_FOOTER_NAV_SEARCH:
            return {
                ...state,
                isDeviceFooterNavHomeActive: false,
                isDeviceFooterNavAccountActive: false,
                isDeviceFooterNavSearchActive: true,
                isDeviceFooterNavFAQsActive: false,
                isDeviceFooterNavSellingActive: false,
            };
        case constants.MOBILE_DEVICE_FOOTER_NAV_FAQS:
            return {
                ...state,
                isDeviceFooterNavHomeActive: false,
                isDeviceFooterNavAccountActive: false,
                isDeviceFooterNavSearchActive: false,
                isDeviceFooterNavFAQsActive: true,
                isDeviceFooterNavSellingActive: false,
            };
        case constants.MOBILE_DEVICE_FOOTER_NAV_SELLING:
            return {
                ...state,
                isDeviceFooterNavHomeActive: false,
                isDeviceFooterNavAccountActive: false,
                isDeviceFooterNavSearchActive: false,
                isDeviceFooterNavFAQsActive: false,
                isDeviceFooterNavSellingActive: true,
            };
        case constants.SHOW_ALL_LISTINGS:
            return {
                ...state,
                showAllListings: action.payload,
            };
        case constants.HAS_NEW_CART_QUANTITY:
            return {
                ...state,
                hasNewCartQuantity: action.payload,
            };
        case constants.PRICE_RANGE_RESET:
            return {
                ...state,
                isPriceRangeReset: action.payload.isPriceRangeReset,
            };
        default:
            // eslint-disable-next-line no-case-declarations
            const localStorageSessionInStock = fetchSessionInStockFromLocalStorage();
            if (localStorageSessionInStock) {
                return {
                    ...state,
                    sessionInStock: localStorageSessionInStock,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const locStorefront = fetchSellerStorefrontFromLocalStorage();
            if (locStorefront !== {}) {
                return {
                    ...state,
                    sellerStorefront: Object.assign(
                        {},
                        {
                            sellerId: locStorefront['sellerId'],
                            sellerName: locStorefront['sellerName'],
                        }
                    ),
                };
            }
            return state;
    }
};
